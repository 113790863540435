const examStatuses = [
  {
    status: 'fail',
    label: 'Échoué',
    class: 'danger'
  },
  {
    status: 'success',
    label: 'Réussi',
    class: 'success'
  },
  {
    status: 'ongoing',
    label: 'En Cours',
    class: 'warning'
  },
]

export default examStatuses;