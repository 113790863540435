import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Helmet from "react-helmet";
import { FormattedMessage } from "react-intl";
import ChangeCase from "change-case";
import Joi from "joi-browser";
import DateFormat from 'dateformat';
import config from "../../config";
import http from "../../services/httpService";
import auth from "../../services/authService";

class SuperAdminCourseSessionForm extends Component {
  state = {
    data: {
      status: '1',
      name: '',
      description: '',
      start_date: '',
      is_default: true,
    },
    notification: []
  };

  getItemId() {
    return this.props.match.params.itemId;
  }

  async componentDidMount() {
    const itemId = this.props.match.params.itemId;
    if (itemId) {
      this.loadItemData(itemId)
    }
  }

  async loadItemData(itemId) {
    const { data: result } = await http.get(
      config.apiEndPoint + "course-session/" + itemId
    );
    result.start_date = DateFormat(result.start_date, "yyyy-mm-dd");
    this.setState({ data: this.cleanData(result) });
  }

  schema = {
    name: Joi.string()
      .required()
      .error(new Error("Nom requis!")),
    description: Joi.optional(),
    is_default: Joi.boolean(),
    start_date: Joi.required().error(new Error("Start Date requis!")),
    status: Joi.required().error(new Error("Statut requis!"))
  };

  doSubmit = async () => {
    try {
      const cleanData = this.cleanData(this.state.data);
      let endpoint = config.apiEndPoint + "course-session/create";

      if (Boolean(this.getItemId())) {
        endpoint = config.apiEndPoint + "course-session/update";
        cleanData.id = this.getItemId();
      }

      const result = await http.post(endpoint, {
        data: cleanData
      });
      if (result) {
        this.props.history.push("/superadmin/course-sessions");
      } else {
        this.showError("Invalid data!");
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400)
        this.showError(ex.response.data);
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const result = Joi.validate(
      {
        name: this.state.data.name,
        description: this.state.data.description,
        start_date: this.state.data.start_date,
        status: this.state.data.status,
      },
      this.schema
    );

    if (result.error) {
      this.showError(result.error.message);
    } else {
      this.doSubmit();
    }
  };

  handleChange = e => {
    const data = { ...this.state.data };
    data[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ data: data });
  };

  showError(message) {
    const notification = { ...this.state.notification };
    notification.message = message;
    this.setState({ notification });
  }

  cleanData(data) {
    const cleanData = {};
    cleanData.name = data.name;
    cleanData.description = data.description;
    cleanData.start_date = data.start_date;
    cleanData.status = data.status;
    cleanData.is_default = Boolean(data.is_default);

    return cleanData;
  }

  render() {
    if (!auth.getAuthUser("superadmin"))
      return <Redirect to="/auth/login/superadmin" />;
    const { data } = this.state;

    return (
      <React.Fragment>
        <FormattedMessage id="superAdmin">
          {title => (
            <Helmet>
              <title>
                {ChangeCase.titleCase(title)} | {config.appName}
              </title>
            </Helmet>
          )}
        </FormattedMessage>
        <section className="section-content container px-6">
          <h2 className="head-title text-uppercase">Gestion des Sessions de Formation</h2>
          <div className="box-listing">
            <div className="text-with-search d-flex justify-content-between align-items-center">
              <h3 className="text-title mb-0 text-uppercase">
                <span>Ajout d'une Nouvelle Session de Formation</span>
                <i className="fa fa-angle-right" aria-hidden="true" />
              </h3>
            </div>
            <div className="box-listing-detail">
              <form className="my-form" onSubmit={this.handleSubmit}>
                {this.state.notification.message && (
                  <div className="alert alert-warning">
                    {this.state.notification.message}
                  </div>
                )}
                <div className="row mx-min5">
                  <div className="col-md-12 pd-x-5">
                    <div className="form-group in-group">
                      <label className="text-bold">Désignation session</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Frais d'inscription"
                        name="name"
                        onChange={this.handleChange}
                        value={data.name}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mx-min5">
                  <div className="col-md-6 pd-x-5">
                    <div className="form-group in-group">
                      <label className="text-bold">Date de début</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Frais d'inscription"
                        name="start_date"
                        onChange={this.handleChange}
                        value={data.start_date}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 pd-x-5">
                    <div className="form-group in-group">
                      <label className="text-bold">Statut</label>
                      <div className="custom-select-option position-relative">
                        <select name="status" className="form-control" onChange={this.handleChange} value={data.status}>
                          <option value="1">Actif</option>
                          <option value="0">Non Actif</option>
                        </select>
                        <div className="arrow-down">
                          <i
                            className="fa fa-angle-down"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 pd-x-5">
                    <div className="form-group in-group">
                      <label className="text-bold">Par défaut</label>
                      <div className="custom-select-option position-relative">
                        <select name="is_default" className="form-control" onChange={this.handleChange} value={data.is_default}>
                          <option value={false}>Non</option>
                          <option value={true}>Oui</option>
                        </select>
                        <div className="arrow-down">
                          <i
                            className="fa fa-angle-down"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mx-min5">
                  <div className="col-md-12 pd-x-5">
                    <div className="form-group in-group">
                      <label className="text-bold">La description</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Frais d'inscription"
                        name="description"
                        onChange={this.handleChange}
                        value={data.description}
                      />
                    </div>
                  </div>
                </div>
                <div className="box-form-button my-form text-center d-flex justify-content-end w-100 mw-100 mt-4">
                  <Link
                    to="/superadmin/course-sessions"
                    className="btn btn-transaprent-border-purple mr-3 text-uppercase px-5 btn-back-liste"
                  >
                    retour
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-purple text-uppercase px-5 btn-back-liste"
                  >
                    enregistrer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default SuperAdminCourseSessionForm;
