import React, { Component } from "react";
import { Redirect, Link, withRouter } from "react-router-dom";
import Helmet from "react-helmet";
import { FormattedMessage } from "react-intl";
import ChangeCase from "change-case";
import Joi from "joi-browser";

import config from "../../config";
import auth from "../../services/authService";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class ForgotPassword extends Component {
    state = {
        data: { email: "" },
        errors: {},
        busy: false,
        show_modal: false,
        userRole: this.props.match.params.userRole
    };

    schema = {
        email: Joi.string()
            .email({ minDomainAtoms: 2 })
            .required()
            .email()
            .error(new Error("adresse email invalide!"))
    };

    doSubmit = async () => {
        try {
            this.setState({ busy: true });
            const token = await auth.forgotPassword(this.state.data);
            this.setState({ busy: false });
            this.setState({ show_modal: true });
        } catch (ex) {
            this.setState({ busy: false });
            if (ex.response && ex.response.status === 400)
                this.showError(ex.response.data);
            if (ex.response && ex.response.status === 422)
                this.showError(ex.response.data);
        }
    }

    onContinueModal() {
        this.setState({ show_modal: false });
        this.props.history.push("/auth/login/" + this.state.userRole);
    }

    handleSubmit = e => {
        e.preventDefault();
        const result = Joi.validate(
            { email: this.state.data.email },
            this.schema
        );

        if (result.error) {
            this.showError(result.error.message);
        } else {
            this.doSubmit();
        }
    };

    handleChange = e => {
        const data = { ...this.state.data };
        data[e.currentTarget.name] = e.currentTarget.value;
        this.setState({ data: data });
    };

    showError(message) {
        const data = { ...this.state.data };
        const errors = { ...this.state.errors };
        data.password = "";
        errors.message = message;
        this.setState({ data, errors });
    }

    render() {
        const user = auth.getAuthUser();
        if (user) return <Redirect to={"/" + user.role.role + "/dashboard"} />;
        return (
            <React.Fragment>
                <FormattedMessage id="login">
                    {title => (
                        <Helmet>
                            <title>
                                {ChangeCase.titleCase('Forgot Password')} | {config.appName}
                            </title>
                        </Helmet>
                    )}
                </FormattedMessage>
                <section className="section-content container">
                    <div className="box-form with-shadow">
                        <div className="row m-0">
                            <div className="col-md-12 p-0">
                                <div className="bg-white box-pdd box-border login-container">
                                    <h2 className="form-title text-center mb-5">Réinitialiser son mot de passe</h2>

                                    <div class="row d-flex justify-content-center">
                                        <div class="col-md-6">
                                            <p class="text-center">Alors comme ¢a tu as oublié ton mot de passe ? Aiesiesie. Bon,
                                            tu as deux possibilités : soit envoyer & mon oncle qui travaille a la
                                            NSA une enveloppe contenant SOO dollars, soit entrer ton adresse mail ci-dessous. A toi de choisir</p>
                                            <form
                                                className="my-form box-form-pdd mt-5"
                                                onSubmit={this.handleSubmit}
                                            >
                                                {this.state.errors.message && (
                                                    <div className="alert alert-warning">
                                                        {this.state.errors.message}
                                                    </div>
                                                )}
                                                <div className="form-group">
                                                    <input
                                                        name="email"
                                                        ref="email"
                                                        autoFocus
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="Entrez votre adresse email"
                                                        value={this.state.data.email}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div className="box-form-button pt-3 mx-auto">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-purple text-uppercase w-100 mb-2"
                                                    >
                                                        Reinitialiser son mot de passe
                                                    </button>

                                                    <button
                                                        type="button"
                                                        className="btn btn-purple btn-outline-purple text-uppercase w-100 mb-2"
                                                        onClick={() => { window.history.back() }}
                                                    >
                                                        Annuler
                                                    </button>

                                                    {/* <Link
                                                        to={"/auth/login/" + this.props.userRole}
                                                        className="btn btn-fb text-uppercase w-100"
                                                    >
                                                        <i className="fa fa-facebook" aria-hidden="true" /> Se
                                                        Connecter avec facebook
                                                    </Link> */}
                                                </div>
                                            </form>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Modal isOpen={this.state.show_modal} centered={true} backdrop={false}>
                    <ModalBody className="p-5 text-center bg-secondary text-white">
                        <p class="mb-5">Vous allez recevoir les instructions de réinitialisation
                        du mot de passe sur votre adresse email dans quelques instants.</p>

                        <button
                            type="button"
                            className="btn btn-purple btn-outline text-uppercase mb-2"
                            onClick={() => { this.onContinueModal() }}
                        >
                            Continuer
                        </button>
                    </ModalBody>
                </Modal>

            </React.Fragment>
        );
    }
}

export default withRouter(ForgotPassword);
