import React, { Component } from "react";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import { FormattedMessage } from "react-intl";
import ChangeCase from "change-case";

import config from "../../config";
import http from "../../services/httpService";
import auth from "../../services/authService";
import Number from "../../helpers/number";
import DataTable from "../Common/DataTable";
import DateFormat from "dateformat";

class SuperAdminCourseSessions extends Component {
  state = {
    column: [
      {
        name: "Numéro",
        key: "id",
        sortable: false,
        className: "text-blue"
      },
      {
        name: "Nom",
        key: "name",
        sortable: false,
        className: "text-blue"
      },
      {
        name: "Date de début",
        key: "start_date",
        sortable: false,
        className: "text-black"
      },
      {
        name: "Par défaut",
        key: "is_default",
        sortable: false,
        className: "text-black"
      },
      {
        name: "Statut",
        key: "status",
        sortable: false,
        className: "text-black"
      }
    ],
    data: null,
    setting: { commerce_base_currency: "CHF" }
  };

  async componentDidMount() {
    const { data: result } = await http.get(
      config.apiEndPoint + "course-session/list"
    );
    const { data: setting } = await http.get(
      config.apiEndPoint + "course-session/list"
    );
    this.setState({ data: this.cleanData(result), setting: setting });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.dataTableSearch();
  };

  handleChange = e => {
    this.dataTableSearch();
  };

  cleanData = rawData => {
    if (rawData.length) {
      return rawData.map(function (item) {
        const data = {};
        data.id = item.id;
        data.name = item.name;
        data.is_default = Boolean(item.is_default) ? `<i class="fa fa-check text-success"></i>` : ``;
        data.start_date = DateFormat(item.start_date, "yyyy-mm-dd");
        data.price = Number.formatedPrice(item.price, "CHF", config.locale.code);
        if (item.status === "0")
          data.status = `<span class="btn bg-warning">Inactif</span>`;
        else data.status = `<span class="btn bg-stabilo">Actif</span>`;
        return data;
      });
    }
    return null;
  };

  dataTableSearch = async () => {
    const user = auth.getAuthUser();
    const { data: result } = await http.post(
      config.apiEndPoint + "venue/user/" + user.id + "/search",
      {
        keyword: this.refs.keyword.value
      }
    );
    this.setState({ data: this.cleanData(result) });
  };

  render() {
    return (
      <React.Fragment>
        <FormattedMessage id="superAdmin">
          {title => (
            <Helmet>
              <title>
                {ChangeCase.titleCase(title)} | {config.appName}
              </title>
            </Helmet>
          )}
        </FormattedMessage>
        <section id="box_listing" className="section-content container px-6">
          <h2 className="head-title text-uppercase">Liste des Sessions de Formation</h2>
          <div className="box-listing">
            <div id="listing_">
              <div className="text-with-search d-flex justify-content-between align-items-center">
                <h3 className="text-title mb-0 text-uppercase">
                  <span className="text-uppercase">Liste des Sessions de Formation</span>{" "}
                  <i className="fa fa-angle-right" aria-hidden="true" />
                </h3>
              </div>
              <div className="listing-table">
                <div className="text-right mb-2">
                  {/* <button
                    type="button"
                    className="btn bg-transparent color-link"
                  >
                    Export en CSV
                  </button> */}
                </div>

                <DataTable
                  column={this.state.column}
                  data={this.state.data}
                  rowLink="/superadmin/course-sessions/detail/"
                />
              </div>
              <div className="box-form-button my-form pt-5 text-center d-flex justify-content-end w-100 mw-100">
                <Link
                  to="/superadmin/course-sessions/create"
                  id="btn_to_edit"
                  className="btn btn-purple text-uppercase px-5 mr-3"
                >
                  Ajout D'Un Nouveau session
                </Link>
                <Link
                  to="/superadmin/dashboard"
                  className="btn btn-transaprent-border-purple text-uppercase px-5"
                >
                  retour
                </Link>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default SuperAdminCourseSessions;
