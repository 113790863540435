import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Helmet from "react-helmet";
import { FormattedMessage } from "react-intl";
import ChangeCase from "change-case";
import Joi from "joi-browser";

import config from "../../config";
import auth from "../../services/authService";

class ResetPassword extends Component {
    state = {
        data: { email: "", password: "", passwordRepeat: "" },
        token: this.props.match.params.token,
        errors: {},
        busy: false,
        success: false
    };


    schema = {
        email: Joi.string()
            .email({ minDomainAtoms: 2 })
            .required()
            .email()
            .error(new Error("Adresse email invalide!")),
        password: Joi.string()
            .min(6)
            .required()
            .error(new Error("Mot de passe requis et minimum 6 caractères")),
        passwordRepeat: Joi.string()
            .valid(Joi.ref("password"))
            .required()
            .error(new Error("Le mot de passe ne correspond pas"))
    };

    componentDidMount() {
        console.log(this.state.data);
    }

    doSubmit = async () => {

        try {
            this.setState({ busy: true });
            const payload = Object.assign({}, this.state.data);
            payload.token = this.state.token;
            const token = await auth.resetPassword(payload);
            this.setState({ busy: false, success: true });
            this.showError("Mot de passe changé avec succès, veuillez vous connecter avec votre nouveau mot de passe!");
        } catch (ex) {
            this.setState({ busy: false });
            if (ex.response && ex.response.status === 400) {
                this.showError(ex.response.data);
            }
            if (ex.response && ex.response.status === 422) {
                this.showError(ex.response.data);
            }
        }
    };

    handleSubmit = e => {
        e.preventDefault();
        const result = Joi.validate(
            this.state.data,
            this.schema
        );

        if (result.error) {
            this.showError(result.error.message);
        } else {
            this.doSubmit();
        }
    };

    handleChange = e => {
        const data = { ...this.state.data };
        data[e.currentTarget.name] = e.currentTarget.value;
        this.setState({ data: data });
    };

    showError(message) {
        const data = { ...this.state.data };
        const errors = { ...this.state.errors };
        data.password = "";
        errors.message = message;
        this.setState({ data, errors });
    }

    render() {
        const user = auth.getAuthUser();
        if (user) return <Redirect to={"/" + user.role.role + "/dashboard"} />;
        return (
            <React.Fragment>
                <FormattedMessage id="login">
                    {title => (
                        <Helmet>
                            <title>
                                {ChangeCase.titleCase('Reset Password')} | {config.appName}
                            </title>
                        </Helmet>
                    )}
                </FormattedMessage>
                <section className="section-content container">
                    <div className="box-form with-shadow">
                        <div className="row m-0">
                            <div className="col-md-12 p-0">
                                <div className="bg-white box-pdd box-border login-container">
                                    <h2 className="form-title text-center">Réinitialiser son mot de passe</h2>

                                    <div class="row d-flex justify-content-center">
                                        <div class="col-lg-6">
                                            <form
                                                className="my-form box-form-pdd"
                                                onSubmit={this.handleSubmit}
                                            >
                                                {this.state.errors.message && (
                                                    <div className="alert alert-warning">
                                                        {this.state.errors.message}
                                                    </div>
                                                )}
                                                <div className="form-group">
                                                    <input
                                                        name="email"
                                                        ref="email"
                                                        autoFocus
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="Entrez votre adresse email"
                                                        value={this.state.data.email}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        name="password"
                                                        ref="password"
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Choisissez un mot de passe"
                                                        value={this.state.data.password}
                                                        onChange={this.handleChange}
                                                    />
                                                    <small className="d-flex align-items-center text-with-icon">
                                                        <i className="fa fa-info mr-2" aria-hidden="true" /> Le Mot de passe doit contenir au moins 6 caractères
                                                    </small>
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        name="passwordRepeat"
                                                        ref="passwordRepeat"
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Répétez votre mot de passe"
                                                        value={this.state.data.passwordRepeat}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div className="box-form-button pt-3 mx-auto">
                                                    <button disabled={this.state.success}
                                                        type="submit"
                                                        className="btn btn-purple text-uppercase w-100 mb-2"
                                                    >
                                                        Reinitialiser son mot de passe
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default ResetPassword;
