import React, { Component } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import ChangeCase from "change-case";

import config from "../../config";
import http from "../../services/httpService";
import DataTableCustomLink from "../Common/DataTableCustomLink";
import examStatuses from "../../helpers/examStatuses";

class AdminStudentCourses extends Component {
  state = {
    column: [
      {
        name: "N° de membre",
        key: "id",
        sortable: false,
        className: "text-blue"
      },
      {
        name: "Élèves",
        key: "name",
        sortable: false,
        className: "text-blue"
      },
      {
        name: "Examen",
        key: "course_name",
        sortable: false,
        className: "text-black"
      },
      {
        name: "Statut",
        key: "status",
        sortable: false,
        className: "text-black"
      },
    ],
    data: [],
    meta: {
      limit: 10,
      current_page: 1,
      last_page: 1
    },
    searchParam: {
      keyword: "",
      exam_status: "",
      limit: 10,
      page: 1
    },
    examStatuses: examStatuses
  };

  async componentDidMount() {
    this.dataTableSearch();
  }

  handleSubmit = e => {
    e.preventDefault();
    const searchParam = this.state.searchParam;
    searchParam.keyword = this.refs.keyword.value;
    this.setState({ searchParam: searchParam });
    this.dataTableSearch();
  };

  handleKeywordChange = e => {
    const keyword = e.target.value;
    this.setState({
      searchParam: {
        ...this.state.searchParam,
        page: 1,
        keyword
      }
    }, () => this.dataTableSearch());
  };

  handleFilterStatus = exam_status => {
    this.setState({
      searchParam: {
        ...this.state.searchParam,
        page: 1,
        exam_status
      }
    }, () => this.dataTableSearch());
  };

  cleanData = rawData => {
    if (rawData.length) {
      return rawData.map(function (item) {
        const data = {};
        data.member_id = item.user_id;
        data.id = item.id;
        data.name = item.user.first_name + ' ' + item.user.last_name;
        data.course_name = item.course.name;

        data.link = '/admin/student-courses/' + item.id;
        data.status = '';

        const _examStatus = examStatuses.find((stat) => stat.status == item.exam_status);
        if (_examStatus) {
          data.status = `<span class="btn bg-${_examStatus.class} text-white">${_examStatus.label}</span>`;
        }

        return data;
      });
    }
    return null;
  };

  dataTableSearch = async () => {
    const { data: result } = await http.get(
      config.apiEndPoint + "user-course/list",
      { params: this.state.searchParam }
    );

    this.setState({ data: this.cleanData(result.data), meta: result.meta });
  };

  changePage = (page) => {
    this.setState({
      searchParam: {
        ...this.state.searchParam,
        page
      }
    }, () => this.dataTableSearch());
  }

  render() {
    const { searchParam } = this.state;
    const _paginationLinks = [];

    for (let i = 1; i <= this.state.meta.last_page; i++) {
      _paginationLinks.push(
        <li className={`page-item ${this.state.searchParam.page == i && 'active'}`}>
          <button class="page-link" type="button" onClick={(e) => this.changePage(i)} href="#">{i}</button>
        </li>
      )
    }
    return (
      <React.Fragment>
        <FormattedMessage id="admin">
          {title => (
            <Helmet>
              <title>
                {ChangeCase.titleCase(title)} | {config.appName}
              </title>
            </Helmet>
          )}
        </FormattedMessage>
        <section className="section-content container">
          <h2 className="head-title text-uppercase">Liste des Examens</h2>
          <div className="box-listing">
            <div id="listing_">
              <div className="text-with-search d-flex justify-content-between align-items-center">
                <h3 className="text-title mb-0 text-uppercase">
                  <span className="text-uppercase">Liste des Examens</span>{" "}
                  <i className="fa fa-angle-right" aria-hidden="true" />
                </h3>
                {/* <a href={`${config.apiEndPoint}member/export`} className="btn bg-transparent color-link">
                  Export en CSV
                </a> */}
              </div>
              <div className="listing-filter mb-5">
                <div className="row">
                  <div className="col-md-9">
                    <div className="topsection">
                      <span
                        className="text-bold mr-3"
                        style={{ width: "60px", display: "inline-block" }}
                      >
                        Statut :
                      </span>
                      <button
                        type="button"
                        onClick={() => this.handleFilterStatus("")}
                        className={
                          "btn btn-status btn-purple btn-transaprent-border-purple-thin text-uppercase mr-2 " +
                          (searchParam.exam_status === "" ? "active" : "")
                        }
                      >
                        Tous
                      </button>

                      {
                        this.state.examStatuses.map((examStatus) => (
                          <button
                            type="button"
                            onClick={() => this.handleFilterStatus(examStatus.status)}
                            className={
                              `btn btn-status btn-outline border-${examStatus.class}  mr-2 ` +
                              (searchParam.exam_status === examStatus.status ? `active bg-${examStatus.class} text-white` : `text-${examStatus.class}`)
                            }
                          >
                            {examStatus.label}
                          </button>
                        ))
                      }
                    </div>
                  </div>
                  <div className="col-md-3">
                    <form
                      className="my-form form-search-listing"
                      onSubmit={this.handleSubmit}
                    >
                      <div className="position-relative">
                        <input
                          type="text"
                          name="keyword"
                          className="form-control"
                          placeholder="Rechercher par nom"
                          onChange={this.handleKeywordChange}
                        />
                        <button type="submit" className="btn">
                          <i className="fa fa-search" aria-hidden="true" />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <DataTableCustomLink
                column={this.state.column}
                data={this.state.data}
                deleteAction={(id) => this.handleRemove(id)}
              />


              <nav aria-label="Page navigation example" className="mx-auto block">
                <ul class="pagination justify-content-center">
                  {_paginationLinks}
                </ul>
              </nav>

              <div className="box-form-button my-form pt-5 text-center d-flex justify-content-end w-100 mw-100">
                <Link
                  to="/admin/dashboard"
                  className="btn btn-transaprent-border-purple text-uppercase mr-3 px-5"
                >
                  retour
                </Link>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default AdminStudentCourses;
