import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Helmet from "react-helmet";
import { FormattedMessage } from "react-intl";
import ChangeCase from "change-case";

import config from "../../config";
import http from "../../services/httpService";
import auth from "../../services/authService";
import number from "../../helpers/number";

class StudentCourseAdd extends Component {
  state = {
    data: [],
    course: [],
    courseSelected: [],
    courseOptionPrice: 300,
    coursePackage: 1,
    coursePackageItems: 1,
    courseIncludeRevision: false,
    setting: [],
    notification: [],
    tos: true,
    total: {
      courseTotal: 0,
      additionalTotal: 0,
      cartTotal: 0,
      cartTotalWithDiscount: 0
    }
  };

  async componentDidMount() {
    const { data: setting } = await http.get(
      config.apiEndPoint + "setting/list"
    );
    const user = auth.getAuthUser();
    const { data: result } = await http.get(
      config.apiEndPoint + "course/list-active/"
    );
    this.setState({ data: user, course: result, setting: setting });
  }

  doSubmit = async () => {
    try {
      const user = auth.getAuthUser();
      const cleanData = this.cleanData();
      const option = {
        id: this.state.coursePackage,
        price: this.state.courseOptionPrice,
      };

      const { data: result } = await http.post(
        `${config.apiEndPoint}course/checkout`,
        { user: user, data: cleanData, option: option }
      );

      if (result) {
        localStorage.removeItem('studentData');

        this.props.history.push(`/student/billing/detail/payment/${result}`);
      } else {
        this.showError('Invalid data!');
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        this.showError(ex.response.data);
      }
    }
  };

  handleSubmit = e => {
    e.preventDefault();

    if (!this.state.tos) {
      return;
    }

    if (this.state.courseSelected.length < this.state.coursePackageItems) {
      this.showError("Veuillez choisir " + this.state.coursePackageItems + " thème" + (this.state.coursePackageItems > 1 ? "s" : ""));
    } else {
      this.doSubmit();
    }
  };

  hadleCourseSelect = e => {
    if (e.currentTarget.checked) {
      this.selectCourse(e.currentTarget.value);
    } else {
      this.removeSelectedCourse(e.currentTarget.value);
    }
  };

  selectCourse = (id) => {
    const _selectedCourse = this.state.course.filter((item) => item.id == id);
    this.setState({ courseSelected: [...this.state.courseSelected, _selectedCourse[0]] }, () => this.calculateCartSubTotal());
  }
  removeSelectedCourse = (id) => {
    this.setState({ courseSelected: this.state.courseSelected.filter((item) => item.id != id) }, () => this.calculateCartSubTotal());
  }

  showError(message) {
    const notification = { ...this.state.notification };
    notification.message = message;
    this.setState({ notification });
  }

  cleanData() {
    var cleanData = [];

    const { courseSelected } = this.state;
    courseSelected.map(item => {
      cleanData.push(item);
    });

    return cleanData;
  }

  /**
   * Calculate Cart total from API
   */
  calculateCartSubTotal = async () => {
    const { courseSelected } = this.state;
    courseSelected.reduce((accumulator, item) => accumulator + item.price, 0);

    try {
      const cleanData = this.cleanData();

      const { data: result } = await http.post(
        `${config.apiEndPoint}course/get-total`,
        { data: cleanData }
      );

      this.setState({ total: result });

    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        this.showError(ex.response.data);
      }
    }
  }

  handleCheckboxChange = (e) => {
    this.setState({
      tos: e.target.checked
    });
  }

  render() {
    const user = auth.getAuthUser();
    if (!user) return <Redirect to="/not-found" />;
    const { course, courseSelected } = this.state;

    var selectedCourse = [];
    if (courseSelected.length > 0) {
      courseSelected.map((item) => {
        selectedCourse.push(item.id);
      })
    }

    return (
      <React.Fragment>
        <FormattedMessage id="student">
          {title => (
            <Helmet>
              <title>
                {ChangeCase.titleCase(title)} | {config.appName}
              </title>
            </Helmet>
          )}
        </FormattedMessage>
        <section className="section-content container student-add-course-form">
          <div className="row m-0">
            <div className="col-md-8 offset-md-2 p-0">
              <div className="box-form">
                <div className="bg-white box-pdd box-border border-2">

                  <div className="mb-5">
                    <h1 className="font-gotham-medium mb-2 h5">
                      Nos formations sur la petente genevoise
                    </h1>
                    <p>Nous vous proposons plusieurs types de formation et des révisions.</p>
                  </div>


                  <form
                    className=""
                    onSubmit={this.handleSubmit}
                  >

                    <table className="table table-courses table-sm w-100">
                      <thead>
                        <tr>
                          <th className="w-75 text-bold">Nos Formations</th>
                          <th><small>Prix Unitaire TTC</small></th>
                        </tr>
                      </thead>
                      <tbody>
                        {course &&
                          course.length > 0 &&
                          course.map((item) => {
                            if (item.type == 'additional') return null;
                            return (
                              <tr key={item.id}>
                                <td>
                                  <div className="checkbox-custom">
                                    <input
                                      type="checkbox"
                                      id={`course-${item.id}`}
                                      value={item.id}
                                      checked={selectedCourse.indexOf(item.id) !== -1}
                                      onChange={this.hadleCourseSelect}
                                    />
                                    <label htmlFor={`course-${item.id}`}>
                                      {item.name}
                                    </label>
                                  </div>
                                </td>
                                <td>{number.formatedPrice(item.price, this.state.setting.commerce_base_currency, config.locale.code)}</td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>

                    <table className="table table-courses table-sm w-100">
                      <thead>
                        <tr>
                          <th className="w-75 text-bold border-bottom border-dark">Options, révisions et autres cours</th>
                          <th className="border-bottom border-dark"><small>Prix Unitaire TTC</small></th>
                        </tr>
                      </thead>
                      <tbody>
                        {course &&
                          course.length > 0 &&
                          course.map((item, index) => {
                            if (item.type == 'main') return null;
                            return (
                              <tr key={item.id}>
                                <td>
                                  <div className="checkbox-custom">
                                    <input
                                      type="checkbox"
                                      id={`course-${item.id}`}
                                      value={item.id}
                                      checked={selectedCourse.indexOf(item.id) !== -1}
                                      onChange={this.hadleCourseSelect}
                                    />
                                    <label htmlFor={`course-${item.id}`}>
                                      {item.name}
                                    </label>
                                  </div>

                                </td>
                                <td>{number.formatedPrice(item.price, this.state.setting.commerce_base_currency, config.locale.code)}</td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>

                    <hr style={{ borderTop: '2px solid' }} />

                    <table className="table table-sm w-100 uppercase text-bold">
                      <thead>
                        <tr>
                          <th className="border-0 text-uppercase text-secondary w-75">Total</th>
                          <th className="border-0">{number.formatedPrice(this.state.total.cartTotal, this.state.setting.commerce_base_currency, config.locale.code)}</th>
                        </tr>
                        <tr>
                          <th className="border-0 text-uppercase uppercase">Total Avec Rabais</th>
                          <th className="border-0">{number.formatedPrice(this.state.total.cartTotalWithDiscount, this.state.setting.commerce_base_currency, config.locale.code)}</th>
                        </tr>
                      </thead>
                    </table>

                    <div className="mb-4">
                      <p>Pour les anciens élèves veuillez prendre contact avec le de formation pour bénéficier d'une remise, cdfgprh@gprh.ch</p>
                    </div>

                    <div className="d-flex">
                      <div className="checkbox-custom">
                        <input type="checkbox" checked={this.state.tos} onChange={() => true} />
                        <label className={!this.state.tos ? 'text-danger' : ''}></label>
                      </div>
                      <a href="#" target={`_blank`} className="text-secondary">
                        <u>
                          J'ai lu et j'accepte les conditions générales du GPRH
                        </u>
                      </a>
                    </div>

                    {this.state.notification.message && (
                      <div className="alert alert-warning">
                        {this.state.notification.message}
                      </div>
                    )}
                    <div className="box-form-button pt-3 d-flex">
                      <Link
                        to="/student/dashboard"
                        className="btn btn-transaprent-border-purple rounded font-bold font-weight-bold text-uppercase px-5 mr-2 border-1"
                      >
                        retour
                      </Link>
                      <button
                        type="submit"
                        onClick={this.props.handleStep}
                        className="btn btn-purple font-bold text-uppercase px-5"
                      >
                        suivant
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment >
    );
  }
}

export default StudentCourseAdd;
