import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Helmet from "react-helmet";
import { FormattedMessage } from "react-intl";
import ChangeCase from "change-case";
import config from "../../config";
import auth from "../../services/authService";
import http from "../../services/httpService";
import examStatuses from "../../helpers/examStatuses";

class AdminStudentCourseForm extends Component {
  state = {
    data: [],
    notification: [],
  };

  async componentDidMount() {
    const { data: result } = await http.get(
      config.apiEndPoint + "user-course/" + this.props.match.params.id
    );
    this.setState({
      data: result
    });
  }

  doSubmit = async () => {
    try {
      const cleanData = this.cleanData(this.state.data);
      const result = await http.post(config.apiEndPoint + "user-course/update", {
        data: cleanData
      });
      if (result) {
        this.props.history.push("/admin/student-courses");
      } else {
        this.showError("Invalid data!");
      }

    } catch (ex) {
      if (ex.response && ex.response.status === 400)
        this.showError(ex.response.data);
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.doSubmit();

  };

  handleChange = e => {
    const data = { ...this.state.data };
    data[e.currentTarget.name] = e.currentTarget.value ? e.currentTarget.value : null;
    this.setState({ data: data });
  };

  showError(message) {
    const notification = { ...this.state.notification };
    notification.message = message;
    this.setState({ notification });
  }

  cleanData(data) {
    const cleanData = {};
    cleanData.id = data.id;
    cleanData.exam_status = data.exam_status;
    return cleanData;
  }

  handleRemove(data) {
    let _confirm = window.confirm('Are you sure you want to delete this user course?');
    if (!_confirm) return false;

    fetch(config.apiEndPoint + 'user-course/' + this.state.data.id, {
      method: "DELETE",
    }).then(() => {
      this.props.history.push('/admin/student-courses');
    });
  }

  render() {
    const user = auth.getAuthUser();
    if (!user) return <Redirect to="/not-found" />;
    const { data } = this.state;

    return (
      <React.Fragment>
        <FormattedMessage id="manager">
          {title => (
            <Helmet>
              <title>
                {ChangeCase.titleCase(title)} | {config.appName}
              </title>
            </Helmet>
          )}
        </FormattedMessage>
        <section id="section_1" className="section-content container">
          <div className="row m-0">
            <div className="col-md-10 offset-md-1">
              <h2 className="head-title mb-4">Compte Course</h2>
              <div className="box-form with-shadow">
                <div className="bg-white box-pdd box-border">
                  <div className="box-form-pdd">
                    <form className="my-form" onSubmit={this.handleSubmit}>
                      {this.state.notification.message && (
                        <div className="alert alert-warning">
                          {this.state.notification.message}
                        </div>
                      )}

                      <div className="student">
                        <label className="text-bold">Élèves</label>
                        <div className="mb-4">
                          <div className="form-group in-group mb-0">
                            <div className="d-flex">
                              <div className="radio-custom text-right mr-3">
                                <input
                                  type="radio"
                                  name="title"
                                  checked={data.user && data.user.title === `mrs`}
                                  value="mrs"
                                  disabled={true}
                                />
                                <label>Madame</label>
                              </div>
                              <div className="radio-custom text-right mr-3">
                                <input
                                  type="radio"
                                  name="title"
                                  checked={data.user && data.user.title === `mr`}
                                  value="mr"
                                  disabled={true}
                                />
                                <label>Monsieur</label>
                              </div>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Nom*"
                                name="last_name"
                                disabled={true}
                                value={data.user ? data.user.last_name : ''}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Prénom*"
                                name="first_name"
                                disabled={true}
                                value={data.user ? data.user.first_name : ''}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="course-detail mb-4">

                        <div className="row">
                          <div className="col-md-8">
                            <label className="text-bold">Cours</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Course Name"
                              name="course_name"
                              disabled={true}
                              value={data.course ? data.course.name : ''}
                            />
                          </div>
                          <div className="col-md-4">
                            <label className="text-bold">Statut</label>
                            <div className="custom-select-option position-relative">
                              <select
                                name={`exam_status`}
                                value={data ? data.exam_status : undefined}
                                className="form-control"
                                onChange={this.handleChange}
                              >
                                <option value="">
                                  -
                                </option>
                                {examStatuses.map((item) => {
                                  return (
                                    <option key={item.status} value={item.status}>
                                      {item.label}
                                    </option>
                                  );
                                })}
                              </select>
                              <div class="arrow-down"><i class="fa fa-angle-down" aria-hidden="true"></i></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <button
                        type="button"
                        className="btn btn-delete mt-2 mr-3 px-4"
                        onClick={() => this.handleRemove()}
                      >
                        Supprimer cet Examen
                      </button>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="box-form-button my-form text-center d-flex w-100 mw-100 mt-5">
                            {(user.role.role === "admin" ||
                              user.role.role === "superadmin") && (
                                <Link
                                  to={"/" + user.role.role + "/student-courses"}
                                  className="btn btn-transaprent-border-purple text-uppercase mr-3 px-4 btn_back_compte"
                                >
                                  annuler
                                </Link>
                              )}
                            {(user.role.role === "owner" ||
                              user.role.role === "student") && (
                                <Link
                                  to={"/" + user.role.role + "/student-courses"}
                                  className="btn btn-transaprent-border-purple text-uppercase mr-3 px-4 btn_back_compte"
                                >
                                  annuler
                                </Link>
                              )}
                            <button
                              type="submit"
                              className="btn btn-purple text-uppercase px-4 btn_back_compte"
                            >
                              enregistrer
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default AdminStudentCourseForm;
