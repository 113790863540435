import React, { Component } from "react";
import { Link } from "react-router-dom";
import config from "../../config";

class DataTableCustomLink extends Component {
 
  render() {
    return (
      <div className="listing-table">
        <table className="table">
          <thead>
            <tr className="listing-head">
              {this.props.column.map(item => {
                if(item.key == 'action') {
                      return(
                      <th key={item.key} className={"text-center"}>
                        <span>
                          {item.name}{" "}
                          {item.sortable && (
                            <i className="fa fa-angle-down" aria-hidden="true" />
                          )}
                        </span>
                      </th>
                      )
                    }
                return (
                  <th key={item.key}>
                    <span>
                      {item.name}{" "}
                      {item.sortable && (
                        <i className="fa fa-angle-down" aria-hidden="true" />
                      )}
                    </span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {this.props.data &&
              this.props.data.map(row => {
                return (
                  <tr key={row.id}>
                    {this.props.data &&
                      this.props.column.map(column => {
                        if(column.key == 'action') {
                          return(
                          <td key={row.id + column.key}>
                            <div className={"listing-row text-center " + column.className}>
                              <button onClick={() =>
                                this.props.deleteAction(row.member_id)} className={"btn-action-delete btn d-block"}><span><i className="fa fa-trash"></i></span>
                              </button>
                            </div>
                          </td>
                          )
                        }
                        return (
                          <td key={row.id + column.key}>
                            <Link
                              to={row.link}
                              className={"listing-row " + column.className}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: row[column.key]
                                }}
                              />
                            </Link>
                          </td>
                        );
                      })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default DataTableCustomLink;
